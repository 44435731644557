import React from "react";
import Layout from "../components/Layout";
import { Tabs, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../redux/alertsSlice";
import axios from "axios";
import { setUser } from "../redux/userSlice";

const Notification = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(user.unseenNotifications);

  const handleMarkAllRead = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/mark-all-notifications-as-seen",
        { userId: user._id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(setUser(response.data.data));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
      message.error("something went wrong");
    }
  };
  const handleDeleteAllRead = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/api/user/delete-all-notifications",
        { userId: user._id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(setUser(response.data.data));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
      message.error("something went wrong");
    }
  };
  return (
    <Layout>
      <h1 className="page-title text-center ">Notifications</h1>
      <Tabs>
        <Tabs.TabPane tab="unseen" key={0}>
          <div className="d-flex justify-content-end">
            <h1
              className="anchor"
              onClick={() => handleMarkAllRead()}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Mark all as seen
            </h1>
          </div>
          {user?.unseenNotifications.map((notification) => (
            <div className="card p-2 " style={{ cursor: "pointer" }}>
              <div
                className="card-text"
                onClick={() => navigate(notification.onClickPath)}
              >
                {notification.message}
              </div>
            </div>
          ))}
        </Tabs.TabPane>

        <Tabs.TabPane tab="seen" key={1}>
          <div className="d-flex justify-content-end">
            <h1
              className="anchor"
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteAllRead()}
            >
              {" "}
              Delete all
            </h1>
          </div>
          {user?.seenNotifications.map((notification) => (
            <div className="card p-2 " style={{ cursor: "pointer" }}>
              <div
                className="card-text"
                onClick={() => navigate(notification.onClickPath)}
              >
                {notification.message}
              </div>
            </div>
          ))}
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );
};

export default Notification;
