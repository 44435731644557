import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { setUser } from "../../redux/userSlice";
import { Table, message } from "antd";
const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const getDoctors = async () => {
    try {
      const res = await axios.get("/api/admin/getAllDoctors", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.data.success) {
        setDoctors(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      // render: (text, record) => <span>{record.isDoctor ? "Yes" : "No"}</span>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="d-flex">
          {record.status === "pending" && (
            <button
              className="btn btn-success"
              onClick={() => {
                handleAccountStatus(record, "approved");
              }}
            >
              Approve
            </button>
          )}
          {record.status === "approved" && (
            <button
              className="btn btn-success"
              onClick={() => {
                handleAccountStatus(record, "blocked");
              }}
            >
              Block
            </button>
          )}
        </div>
      ),
    },
  ];

  const handleAccountStatus = async (record, status) => {
    try {
      const res = await axios.post(
        "/api/admin/changeAccountStatus",
        { doctorId: record._id, userId: record.userId, status: status },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        window.location.reload();
      }
    } catch (error) {
      message.error("Error in handle account status");
    }
  };
  useEffect(() => {
    getDoctors();
  }, []);
  return (
    <Layout>
      <h1 className="text-center"> DoctorsList</h1>
      <Table columns={columns} dataSource={doctors} />
    </Layout>
  );
};

export default DoctorsList;


