import React from "react";
import { useState } from "react";
import "../layout.css";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar, Badge, message } from "antd";
//  import remixicons from "remixicon";
const Layout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  // console.log(user);
  const userMenu = [
    {
      name: "Home",
      path: "/",
      icons: "fa-solid fa-house",
    },

    {
      name: "Appointments",
      path: "/appointments",
      icons: "fa-solid fa-list",
    },

    {
      name: "Apply Doctor",
      path: "/apply-doctor",
      icons: "fa-solid fa-user-doctor",
    },

    // {
    //   name: "profile",
    //   path: "/profile",
    //   icons: "fa-solid fa-user",
    // },
  ];

  const doctorMenu = [
    {
      name: "Home",
      path: "/",
      icons: "fa-solid fa-house",
    },

    {
      name: "Appointments",
      path: "/doctor-appointments",
      icons: "fa-solid fa-list",
    },

    {
      name: "profile",
      path: `/doctor/profile/${user?._id}`,
      icons: "fa-solid fa-user",
    },
  ];
  const adminMenu = [
    {
      name: "Home",
      path: "/",
      icons: "fa-solid fa-house",
    },
    {
      name: "users",
      path: "/admin/users",
      icons: "fa-solid fa-users",
    },
    {
      name: "Doctors",
      path: "/admin/doctors",
      icons: "fa-solid fa-user-doctor",
    },
    // {
    //   name: "profile",
    //   path: "/profile",
    //   icons: "fa-solid fa-user",
    // },
    // {
    //   name:'Logout',
    //   path:'/logout',
    //   icons:"fa-solid fa-right-from-bracket"

    // }
  ];

  const menuToBeRendered = user?.isAdmin
    ? adminMenu
    : user?.isDoctor
    ? doctorMenu
    : userMenu;
  const role = user?.isAdmin ? "Admin" : user?.isDoctor ? "Doctor" : "User";

  return (
    <div className="main ">
      <div className="d-flex layout">
        <div className={`${collapsed ? `collapsed-sidebar` : `sidebar`}`}>
          <div className="sidebar-header">
            {!collapsed ? <h1>StayHealthy</h1> : <h1>SH</h1>}
            <h1 className="normal-text">{role}</h1>
          </div>

          <hr />

          <div className="menu">
            {menuToBeRendered.map((menu) => {
              const isActive = location.pathname === menu.path;
              return (
                <div
                  className={`d-flex menu-item ${
                    isActive && "active-menu-item"
                  }`}
                >
                  <i class={menu.icons} />
                  {!collapsed && <Link to={menu.path}>{menu.name}</Link>}
                </div>
              );
            })}
            <div
              className={`d-flex menu-item `}
              onClick={() => {
                localStorage.clear();
                message.success("Logout Successfully");
                navigate("/login");
              }}
            >
              <i class="fa-solid fa-right-from-bracket"></i>
              {!collapsed && <Link to={"/logout"}>Logout</Link>}
            </div>
          </div>
        </div>

        <div className="content">
          <div className="header">
            {collapsed ? (
              <i
                className="fa-solid fa-bars header-action-icon"
                onClick={() => setCollapsed(false)}
              ></i>
            ) : (
              <i
                className="fa-solid fa-xmark header-action-icon"
                onClick={() => setCollapsed(true)}
              ></i>
            )}
            <div className="header-content" style={{ cursor: "pointer" }}>
              <Badge
                count={user?.unseenNotifications.length}
                onClick={() => navigate("/notifications")}
              >
                <i className="fa-solid fa-bell header-action-icon mr-2"></i>
              </Badge>
              <Link className="anchor mx-3 text-decoration-none" >
                {user?.name}
              </Link>
            </div>
          </div>

          <div className="body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
