import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../components/Layout";
import { Row } from "antd";
import DoctorList from "../components/DoctorList";
const Home = () => {
  const [doctors, setDoctor] = useState([]);
  const getData = async () => {
    try {
      const res = await axios.get("/api/user/getAllDoctors", {
    

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log(res.data);
      if (res.data.success) {
        setDoctor(res.data.data);
      }

      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Layout>
      <h1 className="text-center">Homepage</h1>
      <Row gutter={20}>
        {doctors && doctors.map((doctor) => <DoctorList doctor={doctor} />)}
      </Row>
    </Layout>
  );
};

export default Home;
