
import React ,{ useEffect, useState }from 'react'
import Layout from '../../components/Layout'
import moment from 'moment'
import axios from 'axios'
import { Table, message } from 'antd'


const DoctorAppointments = () => {

    const [appointments, setAppointments] = useState([]);

    const getAppointments = async () => {
      try {
        const res = await axios.get("/api/doctor/doctor-appointments", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (res.data.success) {
          setAppointments(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      getAppointments();
    }, []);
         
    const handleStatus= async( record ,status)=>{
        try {
            const res= await axios.post('/api/doctor/update-status',{appointmentsId:record._id,status},
              {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
              }
            )
            if(res.data.success)
            {
              message.success(res.data.message)  
              getAppointments();
            }
            
        } catch (error) {
            console.log(error);
            message.error('Someting Went Wrong')

        }
    };
    const columns = [
        {
          title: "ID",
          dataIndex: "_id",
        },
        
        {
          title: "Date & Time",
          dataIndex: "date",
          render: (text, record) => (
            <span>
              {(record.date)} &nbsp;
              {(record.time)}
            </span>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
        },
        {
            title:"Action",
            dataIndex:'actions',
            render:(text,record)=>(
                <div className="d-flex">
                    {record.status==='pending' &&(
                        <div className="d-flex">
                            <button className="btn btn-success" onClick={()=>handleStatus(record,'approved')}> Approved</button>
                            <button className="btn btn-danger ms-2" onClick={()=>handleStatus(record,'rejected')}> Reject</button>
                        </div>
                    )}
                </div>
            )
        }
      ];
  return (
    <Layout>
    <h1>Appoinmtnets Lists</h1>
    <Table columns={columns} dataSource={appointments} />
  </Layout>
  )
}

export default DoctorAppointments