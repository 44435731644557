import React from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";

const DoctorList = ({ doctor }) => {
  // console.log(doctor.timings[0]);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="card  m-2 "
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/doctor/book-appointments/${doctor._id}`)}
      >
        <div className="card-header">
          Dr . {doctor.firstName} {doctor.lastName}
        </div>
        <div className="card-body">
          <p>
            <b>Specialization</b>: {doctor.specialization}
          </p>
          <p>
            <b>Experience</b>: {doctor.experience}
          </p>

          <p>
            <b>Address</b>: {doctor.address}
          </p>
      
          <p>
            <b>Fees Per consulataion</b>: {doctor.feePerCunsultation}
          </p>
          <p>
            <b>Timings</b>: {doctor.timings[0]}-{doctor.timings[1]}
          </p>
        </div>
      </div>
    </>
  );
};

export default DoctorList;
