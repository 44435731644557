// import React, { useEffect, useState } from 'react'
// import Layout from '../../components/Layout'
// import { useSelector } from 'react-redux'
// import axios from 'axios'
// import {useParams} from 'react-router-dom'
// import { Col, Form, Input, Row, TimePicker, Button } from "antd";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// // import { hideLoading, showLoading } from "../redux/alertsSlice";

// import toast from "react-hot-toast";
// import { hideLoading, showLoading } from '../../redux/alertsSlice'
// import moment from 'moment'

// const DoctorProfile = () => {
//     const {user}=useSelector((state)=>state.user)
//     const [doctor,setDoctor]=useState(null)
//     const params=useParams()
//     const navigate=useNavigate();
//     const dispatch=useDispatch();

//     // update doctor
//     const handleFinish = async (values) => {
//         // console.log(values);
//         try {
//           dispatch(showLoading());
//           const response= await axios.post('/api/doctor/updateProfile',
//           {...values,userId:user._id,timings:[
//             moment(values.timing[0],'HH:mm'),
//             moment(values.timing[1] ,'HH:mm')
//           ]},
//           {
//               headers:{
//                     Authorization:`Bearer ${localStorage.getItem('token')}`
//            }
//           });

//           console.log(response);
//           dispatch(hideLoading());

//           if(response.data.success)
//           {
//             // console.log(response.data.message);
//             toast.success(response.data.message);
//             toast("Redirecting to Home page");
//             navigate("/");
//           }
//           else
//           {
//              toast.error(response.data.message);
//           }
//         }
//         catch (error)
//         {
//           dispatch(hideLoading());
//           toast.error("something went wrong");
//         }

//     }

//     // get doctor details

//     const getDoctorInfo=async(req,res)=>{
//         try {

//             const res=await axios.post('/api/doctor/getDoctorInfo',{userId:params.id},
//                    {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem("token")}`,
//                         }
//                    })

//             if(res.data.success)
//             {
//                setDoctor(res.data.data)
//             }
//         } catch (error) {
//             console.log(error);
//             // res.status(500).send({
//             //     success:false,
//             //     message:"error in getting  doctor information"
//             // })

//         }

//     }
//     useEffect(()=>{
//         getDoctorInfo()
//     },[])

//   return (
//     <Layout>
//         <h1 className='text-center'> Manage Profile </h1>
//         {doctor && (<Form layout="vertical" onFinish={handleFinish} className="m-3" initialValues={{...doctor,
//         timings:[
//             moment(doctor.timings[0],'HH:mm'),
//             moment(doctor.timings[1],'HH:mm')
//         ]}}>
//         <h4 className="">Personal Details : </h4>
//         <hr />
//         <Row gutter={20}>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="First Name"
//               name="firstName"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="text" placeholder="your first name" />
//             </Form.Item>
//           </Col>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="Last Name"
//               name="lastName"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="text" placeholder="your last name" />
//             </Form.Item>
//           </Col>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="Phone No"
//               name="phoneNumber"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="text" placeholder="your contact no" />
//             </Form.Item>
//           </Col>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="Email"
//               name="email"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="email" placeholder="your email address" />
//             </Form.Item>
//           </Col>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item label="Website" name="webSite">
//               <Input type="text" placeholder="your website" />
//             </Form.Item>
//           </Col>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="Address"
//               name="address"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="text" placeholder="your clinic address" />
//             </Form.Item>
//           </Col>
//         </Row>
//         <h4>Professional Details :</h4>
//         <hr />
//         <Row gutter={20}>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="Specialization"
//               name="specialization"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="text" placeholder="your specialization" />
//             </Form.Item>
//           </Col>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="Experience"
//               name="experience"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="text" placeholder="your experience" />
//             </Form.Item>
//           </Col>
//           <Col xs={24} md={24} lg={8}>
//             <Form.Item
//               label="Fees Per Cunsaltation"
//               name="feePerCunsultation"
//               required
//               rules={[{ required: true }]}
//             >
//               <Input type="text" placeholder="your contact no" />
//             </Form.Item>
//           </Col>
//           {/* <Col xs={24} md={24} lg={8}>
//             <Form.Item label="Timings" name="timings" required>
//               <TimePicker.RangePicker format="HH:mm" />
//             </Form.Item>
//           </Col> */}
//           <Col xs={24} md={24} lg={8}></Col>
//           <Col xs={24} md={24} lg={8}>
//             <div className="d-flex justify-content-end">
//                    <Button className='primary-button button-1' htmlType='submit'>SUBMIT</Button>
//             </div>
//           </Col>

//         </Row>

//       </Form>)}
//     </Layout>
//   )
// }

// export default DoctorProfile

// import React, { useEffect, useState } from "react";
// import Layout from "./../../components/Layout";
// import axios from "axios";
// import { useParams, useNavigate } from "react-router-dom";
// import { Col, Form, Input, Row, TimePicker, message } from "antd";
// import { useSelector, useDispatch } from "react-redux";

// import moment from "moment";

// import React, { useEffect, useState } from 'react'
// import Layout from '../../components/Layout'
// import { useSelector } from 'react-redux'
// import axios from 'axios'
// import {useParams} from 'react-router-dom'
// import { Col, Form, Input, Row, TimePicker, Button } from "antd";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// // import { hideLoading, showLoading } from "../redux/alertsSlice";

// import toast from "react-hot-toast";
// import { hideLoading, showLoading } from '../../redux/alertsSlice'
// import moment from 'moment'
// const DoctorProfile = () => {
//   const { user } = useSelector((state) => state.user);
//   const [doctor, setDoctor] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const params = useParams();
//   // update doc ==========
//   //handle form
//   console.log(user);
//   const handleFinish = async (values) => {
//     try {
//       dispatch(showLoading());
//       const res = await axios.post(
//         "/api/doctor/updateProfile",
//         {
//           ...values,
//           userId: user._id,
//           timings:[
//                         moment(values.timings[0]).format("HH:mm"),
//                         moment(values.timings[1]).format("HH:mm"),
//                       ]
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       console.log(res.data.success)

//       dispatch(hideLoading());
//       if (res.data.success) {
//         toast.success(res.data.message);
//         navigate("/");
//       } else {
//         toast.error(res.data.success);
//       }
//     } catch (error) {
//       dispatch(hideLoading());
//       console.log(error);
//       toast.error("Somthing Went Wrrong ");
//     }
//   };
//   // update doc ==========

//   //getDOc Details
//   const getDoctorInfo = async () => {
//     try {
//       const res = await axios.post(
//         "/api/doctor/getDoctorInfo",
//         { userId: params.id },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       if (res.data.success) {
//         setDoctor(res.data.data);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getDoctorInfo();
//     //eslint-disable-next-line
//   }, []);
//   return (
//     <Layout>
//       <h1>Manage Profile</h1>
//       {doctor && (
//         <Form
//           layout="vertical"
//           onFinish={handleFinish}
//           className="m-3"
//           initialValues={{
//             ...doctor,
//             timings:[
//               moment(doctor.timings[0]).format("HH:mm"),
//               moment(doctor.timings[1]).format("HH:mm")
//             ]
//           }}
//         >
//           <h4 className="">Personal Details : </h4>
//           <Row gutter={20}>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="First Name"
//                 name="firstName"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="text" placeholder="your first name" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="Last Name"
//                 name="lastName"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="text" placeholder="your last name" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="Phone No"
//                 name="phone"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="text" placeholder="your contact no" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="Email"
//                 name="email"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="email" placeholder="your email address" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item label="Website" name="website">
//                 <Input type="text" placeholder="your website" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="Address"
//                 name="address"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="text" placeholder="your clinic address" />
//               </Form.Item>
//             </Col>
//           </Row>
//           <h4>Professional Details :</h4>
//           <Row gutter={20}>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="Specialization"
//                 name="specialization"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="text" placeholder="your specialization" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="Experience"
//                 name="experience"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="text" placeholder="your experience" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item
//                 label="Fees Per Cunsaltation"
//                 name="feesPerCunsaltation"
//                 required
//                 rules={[{ required: true }]}
//               >
//                 <Input type="text" placeholder="your contact no" />
//               </Form.Item>
//             </Col>
//             <Col xs={24} md={24} lg={8}>
//               <Form.Item label="Timings" name="timings" required>
//                 <TimePicker.RangePicker format="HH:mm" />
//               </Form.Item>
//             </Col>

//             {/* <Col xs={24} md={24} lg={8}>
//             <Form.Item label="Timings" name="timings" required>
//               <TimePicker.RangePicker format="HH:mm" />
//             </Form.Item> */}
//           {/* </Col> */}
//             <Col xs={24} md={24} lg={8}></Col>
//             <Col xs={24} md={24} lg={8}>
//             <div className="d-flex justify-content-end">
//                    <Button className='primary-button button-1' htmlType='submit'>Update</Button>
//             </div>
//           </Col>

//           </Row>
//         </Form>
//       )}
//     </Layout>
//   );
// };

// export default DoctorProfile;

// import React, { useEffect, useState } from "react";
// import Layout from "./../../components/Layout";
// import axios from "axios";
// import { useParams, useNavigate } from "react-router-dom";
// import { Col, Form, Input, Row, TimePicker, message } from "antd";
// import { useSelector, useDispatch } from "react-redux";
// import { showLoading, hideLoading } from "../../redux/features/alertSlice";
// import moment from "moment";

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Col, Form, Input, Row, TimePicker, Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import DoctorForm from "../../components/DoctorForm";

const DoctorProfile = () => {
  const { user } = useSelector((state) => state.user);
  const [doctor, setDoctor] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // update doc ==========
  //handle form
  const handleFinish = async (values) => {
    try {
      // console.log(values);
      dispatch(showLoading());
      const res = await axios.post(
        "/api/doctor/updateProfile",
        {
          ...values,
          userId: user._id,
          timings: [
            moment(values.timings[0]).format("HH:mm"),
            moment(values.timings[1]).format("HH:mm"),
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (res.data.success) {
        setDoctor(res.data.data);
        toast.success(res.data.message);
        navigate("/");
      } 
      else {
        toast.error(res.data.success);
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
      toast.error("Somthing Went Wrrong ");
    }
  };
  // update doc ==========

  //getDOc Details
  const getDoctorInfo = async () => {
    try {
      const res = await axios.post(
        "/api/doctor/getDoctorInfo",
        { userId: params.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setDoctor(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctorInfo();
    //eslint-disable-next-line
  }, []);
  const format = "HH:mm";
  return (
    <Layout>
      <h1>Manage Profile</h1>

      {/* <DoctorForm onFinish={handleFinish}/> */}
      {doctor && (
        <Form
          layout="vertical"
          onFinish={handleFinish}
          className="m-3"
          initialValues={{
            ...doctor,
            timings: [
              moment(doctor.timings[0], "HH:mm"),
              moment(doctor.timings[1], "HH:mm"),
            ],
          }}
        >
          <h4 className="">Personal Details : </h4>
          <hr />
          <Row gutter={20}>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="First Name"
                name="firstName"
                required
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="your first name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Last Name"
                name="lastName"
                required
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="your last name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Phone No"
                name="phone"
                required
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="your contact no" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Email"
                name="email"
                required
                rules={[{ required: true }]}
              >
                <Input type="email" placeholder="your email address" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item label="Website" name="website">
                <Input type="text" placeholder="your website" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Address"
                name="address"
                required
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="your clinic address" />
              </Form.Item>
            </Col>
          </Row>
          <h4>Professional Details :</h4>
          <hr />
          <Row gutter={20}>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Specialization"
                name="specialization"
                required
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="your specialization" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Experience"
                name="experience"
                required
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="your experience" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Form.Item
                label="Fees Per Cunsaltation"
                name="feePerCunsultation"
                required
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="your contact no" />
              </Form.Item>
            </Col>

            <Col xs={24} md={24} lg={8}>
              <Form.Item label="Timings" name="timings" required>
                {/* <TimePicker.RangePicker format={format} /> */}
                <TimePicker.RangePicker format={format} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={8}></Col>

            <Col xs={24} md={24} lg={8}>
              <div className="d-flex justify-content-end">
                <Button className="primary-button button-1" htmlType="submit">
                  UPDATE
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Layout>
  );
};

export default DoctorProfile;
