// import React, { useState, useEffect } from "react";
// import Layout from "../components/Layout";
// import axios from "axios";
// import moment from 'moment'
// import { Table } from "antd";

// const Appointments = () => {
//   const [appointments, setAppointments] = useState();

//   const getAppointments = async () => {
//     try {
//       const res = await axios.get("/api/user/user-appointments", {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       if(res.data.success)
//       {
//         setAppointments(res.data.data)
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   useEffect(()=>{
//     getAppointments();
//   },[])
//   const columns=[
//     {
//         title:'ID',
//         dataIndex:'_id'
//     },
//     {
//         title:'Name',
//         dataIndex:'name',
//         render:(text,record)=>{
//             <span> 
//                 {record.doctorId.firstName}  {record.doctorId.lastName}
//             </span>
            
//         }
//     },
//     {
//         title:'Phone',
//         dataIndex:'phoneNumber',
//         render:(text,record)=>{
//             <span> 
//                 {record.doctorId.phoneNumber}  
//             </span>
            
//         }
//     },
//     {
//         title:'Date and time',
//         dataIndex:'date',
//         render:(text,record)=>{
//             <span> 
//                 { moment(record.date).format('DD-MM-YYYY')} &nbsp;
//                 { moment(record.time).format('HH:mm')}  
//             </span>
            
//         }
//     },
//     {
//         title:'Status',
//         dataIndex:'status',
     
//     },

//   ]
//   return (
//     <Layout>
//       <h1>Appointments List</h1>
//       <Table columns={columns} dataSource={appointments}/>
//     </Layout>
//   );
// };

// export default Appointments;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./../components/Layout";
import moment from "moment";
import { Table } from "antd";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);

  const getAppointments = async () => {
    try {
      const res = await axios.get("/api/user/user-appointments", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.data.success) {
        setAppointments(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAppointments();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      render: (text, record) => (
        <span>
          {record.date} &nbsp;
          {record.time}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  return (
    <Layout>
      <h1>Appoinmtnets Lists</h1>
      <Table columns={columns} dataSource={appointments} />
    </Layout>
  );
};

export default Appointments;
